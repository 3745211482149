const DEFAULT_LOCALE = "en-US";

export function getLocale(): string {
    if (typeof window === "undefined") {
        return DEFAULT_LOCALE;
    }

    if (typeof navigator.languages !== "undefined") {
        // this is making en as priority when available
        if (navigator.languages.length > 1) {
            const enLocal = navigator.languages.find((item) => item === "en");
            if (enLocal) return enLocal;
        }
        return navigator.languages[0];
    }

    return navigator.language || (navigator as any).browserLanguage || DEFAULT_LOCALE; // "browserLanguage" is IE10- fallback
}
