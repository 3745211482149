import * as React from "react";
import { SVGProps } from "react";
const SvgPseudoQrCode = (props: SVGProps<SVGSVGElement>) => <React.Fragment>
        <svg className="pseudo-qr-code" viewBox="0 0 27 27" fill="#fff" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1.368 9.082h6.944c.59 0 1.07-.478 1.07-1.07V1.07c0-.59-.48-1.07-1.07-1.07H1.368C.778 0 .298.48.298 1.07v6.943c0 .59.48 1.07 1.07 1.07Zm1.07-6.942H7.24v4.803H2.438V2.14ZM25.93.001h-6.943c-.59 0-1.07.479-1.07 1.07v6.943c0 .59.48 1.07 1.07 1.07h6.943A1.07 1.07 0 0 0 27 8.013V1.07c0-.591-.479-1.07-1.07-1.07Zm-1.07 6.943h-4.803V2.14h4.803v4.803ZM8.312 17.918H1.368c-.59 0-1.07.479-1.07 1.07v6.942c0 .591.48 1.07 1.07 1.07h6.944c.59 0 1.07-.479 1.07-1.07v-6.942c0-.591-.48-1.07-1.07-1.07Zm-1.07 6.943H2.438v-4.803h4.804v4.803ZM25.93 17.918h-6.943a1.07 1.07 0 0 0-1.07 1.07v6.942c0 .591.48 1.07 1.07 1.07h6.943c.591 0 1.07-.479 1.07-1.07v-6.942c0-.591-.479-1.07-1.07-1.07Zm-1.07 6.943h-4.803v-4.803h4.803v4.803ZM13.537.001c-.591 0-1.07.479-1.07 1.07v6.943a1.07 1.07 0 0 0 2.14 0V1.07c0-.591-.48-1.07-1.07-1.07ZM25.93 12.467h-6.943a1.07 1.07 0 1 0 0 2.14h6.943a1.07 1.07 0 1 0 0-2.14ZM13.537 23.143a1.07 1.07 0 0 0-1.07 1.07v1.717a1.07 1.07 0 0 0 2.14 0v-1.717c0-.591-.48-1.07-1.07-1.07ZM13.537 12.467H9.434a1.07 1.07 0 0 0 0 2.14h3.036v5.5a1.07 1.07 0 0 0 2.14 0v-6.57a1.07 1.07 0 0 0-1.073-1.07ZM1.07 14.607h3.77a1.07 1.07 0 0 0 0-2.14H1.07a1.07 1.07 0 1 0 0 2.14Z" fill="#CCC" />
        </svg>
        <style global jsx>{`
            .pseudo-qr-code {
                min-width: 100%;
                min-height: 100%;
            }
        `}</style>
    </React.Fragment>;
export default SvgPseudoQrCode;