import { isSrcCached } from "@novel/shared/utils/isSrcCached";
import React, { ReactEventHandler, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
const StyledImage = styled.img<{
  isLoaded: boolean;
}>`
    position: relative;
    transition: opacity 0.15s ease-in-out;
    ${props => !props.isLoaded ? `
		visibility: hidden;
		opacity: 0;
	` : `
		opacity: 1;
	`}
`;
interface FadeInOnLoadMediaProps {
  readonly onLoad?: ReactEventHandler<HTMLImageElement>;
  readonly onError?: (e: any) => void;
  readonly src?: string;
  readonly alt?: string;
  readonly className?: string;
  readonly style?: React.CSSProperties;
  readonly height?: number | string;
  readonly width?: number | string;
  readonly type?: "image" | "video";
  // video only
  readonly poster?: string;
  readonly controls?: boolean;
  readonly muted?: boolean;
  readonly loop?: boolean;
  readonly autoPlay?: boolean;
}
export const FadeInOnLoadMedia = React.forwardRef<HTMLVideoElement | HTMLImageElement, FadeInOnLoadMediaProps>(function FadeInOnLoadMedia({
  src,
  alt,
  className,
  onLoad,
  onError,
  style,
  height,
  width,
  type = "image",
  poster,
  controls,
  muted,
  loop,
  autoPlay
}: FadeInOnLoadMediaProps, ref): JSX.Element {
  const [isLoaded, setIsLoadedState] = useState(!!src && isSrcCached(src));
  const setLoadedCb: ReactEventHandler<HTMLImageElement> = useCallback(e => {
    setIsLoadedState(true);
    if (onLoad) {
      onLoad(e);
    }
  }, [onLoad]);
  const setHadErrorCb = useCallback((e: any) => {
    if (onError) {
      onError(e);
    }
  }, [onError]);
  const finalStyleObj = useMemo(() => ({
    ...(typeof height !== "undefined" ? {
      maxHeight: height
    } : {}),
    ...(typeof width !== "undefined" ? {
      maxWidth: width
    } : {}),
    ...style
  }), [height, width, style]);
  return <React.Fragment>
            {type === "video" ? <video ref={ref as any} controls={controls} muted={muted} poster={poster} loop={loop} onError={setHadErrorCb} className={className} style={finalStyleObj} height={height} width={width} autoPlay={autoPlay}>
                    <source src={src} type="video/webm" />
                    <source src={src} type="video/mp4" />
                </video> : <StyledImage ref={ref as any} src={src} alt={alt} isLoaded={typeof window === "undefined" || isLoaded} // handling SSR
    onLoad={setLoadedCb} onError={setHadErrorCb} className={className} style={finalStyleObj} height={height} width={width} />}
        </React.Fragment>;
});