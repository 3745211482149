import React, { useEffect, useState } from "react";
import cx from "classnames";
import { formatMoney, StoreCreditCurrencyCode } from "@novel/shared/utils/formatMoney";
import { claimBalance } from "@novel/shared/utils/claimBalance";
import { IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { ICustomerRewardsStatus } from "@novel/shared/interfaces/CustomerRewardsStatus";
import { IOrgHandle } from "@novel/shared/interfaces/OrgHandle";
import { getPassLinkForHandle } from "@novel/shared/utils/getPassLinkForHandle";
import { IOrgCustomerRelationshipWithRewardsStatusAndTier } from "@novel/shared/interfaces/OrgCustomerRelationship";
interface PassAnimationProps {
  readonly className?: string;
  readonly orgHandle?: IOrgHandle;
  readonly rewardsTier: Omit<IRewardsTierCreate, "tierIndex">;
  readonly currencyCode: StoreCreditCurrencyCode;
  readonly referredBy?: IOrgCustomerRelationshipWithRewardsStatusAndTier;
  readonly width?: string;

  // TODO: handle preview case - only different if welcome bonus applies
  readonly rewardsStatus?: ICustomerRewardsStatus;
  readonly isPreview?: boolean;
}
export function PassAnimation({
  orgHandle,
  className,
  rewardsTier,
  currencyCode,
  width,
  referredBy
}: PassAnimationProps): JSX.Element {
  const [svgImage, setSvgImage] = useState<string>("");
  const logoImageUrl = rewardsTier.appleLogoImageUrl || rewardsTier.appleLogoImageUrlx2 || rewardsTier.appleLogoImageUrlx3 || rewardsTier.googleLogoImageUrl;
  const stripImageUrl = rewardsTier.stripImageUrl || rewardsTier.stripImageUrlx2 || rewardsTier.stripImageUrlx3;

  // TODO: handle what-if here
  const balance = claimBalance(rewardsTier, currencyCode, referredBy?.rewardsStatus?.currentRewardsTier);

  // fetch and style svg
  useEffect(() => {
    let svgPath = "OrderClaimLogin.svg";
    let bubbleText = "";
    if (!!rewardsTier.hasWelcomeReward && balance) {
      svgPath = "OrderClaimLogin.svg";
    } else if (rewardsTier.hasReferralReward) {
      svgPath = "orderClaim.svg";
      bubbleText = "Refer and";
    } else if (rewardsTier.hasSpendReward) {
      svgPath = "orderClaim.svg";
      bubbleText = "Install to";
    } else {
      svgPath = "orderClaimNoReferral.svg";
    }
    fetch(`${orgHandle ? getPassLinkForHandle(orgHandle) : ""}/assets/${svgPath}`, {
      method: "GET",
      headers: {
        "Content-Type": "image/svg+xml"
      }
    }).then(response => response.text()).then(async svgText => {
      // apply styling
      svgText = svgText.replace(/#fd4ca0/g, rewardsTier.backgroundColor || "#000");
      // change bubbletext
      svgText = svgText.replace(/{bubbleText}/g, bubbleText);
      // text color change
      svgText = svgText.replace(/id="order-claim-flow-alt-s-tspan1"/g, `id="order-claim-flow-alt-s-tspan1" style='fill: ${rewardsTier.textColor || "#fff"};'`);
      if (width) {
        svgText = svgText.replace(/id="order-claim-flow-alt"/g, `id="order-claim-flow-alt" style='width: ${width};'`);
      }
      svgText = svgText.replace(/id="order-claim-flow-alt-u-amount"/g, `id="order-claim-flow-alt-u-amount" style='fill: ${rewardsTier.textColor || "#fff"};'`);
      svgText = svgText.replace(/style="[^"]*"/g, `style="color:${rewardsTier.textColor || "#fff"}"`);

      // images
      svgText = svgText.replace(/{bannerUrl}/g, stripImageUrl);
      svgText = svgText.replace(/{logoUrl}/g, logoImageUrl);
      setSvgImage(svgText);
    });
  }, [logoImageUrl, stripImageUrl, rewardsTier, orgHandle, balance, width]);

  // set currency and start animation
  useEffect(() => {
    if (svgImage.includes("svg") && typeof document !== "undefined") {
      const scriptElement = document.getElementById("novel-svg-claim-script");
      if (scriptElement) {
        const scriptFunction = new Function(scriptElement.textContent ?? "");
        scriptFunction();
      }
      const numberTextBalance = document.getElementById("order-claim-flow-alt-s-tspan2");
      if (balance && numberTextBalance) {
        // TODO: center text if currency is not points
        numberTextBalance.innerHTML = `${formatMoney(balance, currencyCode)} ${currencyCode === "PTS" ? "POINTS" : ""}`;
        numberTextBalance.style.cssText += `font-size: ${currencyCode === "PTS" ? "7px" : "8px"};`;
      }
      const balanceHeadTextBalance = document.getElementById("order-claim-flow-alt-s-tspan3");
      if (balanceHeadTextBalance) {
        balanceHeadTextBalance.innerHTML = "";
      }
    }
  }, [svgImage, balance, currencyCode, rewardsTier.hasWelcomeReward]);
  return <React.Fragment>
            <div dangerouslySetInnerHTML={{
      __html: svgImage
    }} id="box" className={cx("svgImageContainer", className)} />
            <style jsx global>{`
                .svgImageContainer svg {
                    transform: translateY(1px);
                }
                .svgImageContainer {
                    overflow: hidden;
                }

                #order-claim-flow-circle {
                    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.1));
                }
            `}</style>
        </React.Fragment>;
}