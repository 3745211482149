import { getLocale } from "@novel/shared/utils/getLocale";

const bigIntSymbol = Symbol("BigInt");

export interface BigIntStr {
    // this is a hack to make it impossible for this type to be instantiated outside of this file.
    [bigIntSymbol]: never;
}

// needed to handle "BigInt" type in DB which comes back from TypeORM as a string
export const BigIntStr = {
    toBigIntStr(value: BigInt | number | bigint): BigIntStr {
        return value.toString() as unknown as BigIntStr;
    },

    toNumber(BigIntStr: BigIntStr | string | number): number {
        return Number(BigIntStr);
    },

    toBigInt(BigIntStr: BigIntStr | string | number | bigint): bigint {
        return toBigInt(BigIntStr);
    },

    max(values: (BigIntStr | number | bigint)[]): BigIntStr {
        return BigIntStr.toBigIntStr(
            values
                .map((v) => toBigInt(v))
                .reduce((accum, curr) => (accum > curr ? accum : curr), toBigInt(0)),
        );
    },

    sum(values: (BigIntStr | number | bigint)[]): BigIntStr {
        return BigIntStr.toBigIntStr(
            values.map((v) => toBigInt(v)).reduce((accum, curr) => accum + curr, toBigInt(0)),
        );
    },

    subtract(value1: BigIntStr | number | bigint, value2: BigIntStr | number | bigint): BigIntStr {
        return BigIntStr.toBigIntStr(toBigInt(value1) - toBigInt(value2));
    },

    equals(num1: BigIntStr | number | bigint, num2: BigIntStr | number | bigint): boolean {
        return toBigInt(num1) === toBigInt(num2);
    },

    invert(value: BigIntStr | number | bigint): BigIntStr {
        return BigIntStr.subtract(0, value);
    },

    greaterThan(value1: BigIntStr | number | bigint, value2: BigIntStr | number | bigint): boolean {
        return toBigInt(value1) > toBigInt(value2);
    },

    greaterThanOrEqual(
        value1: BigIntStr | number | bigint,
        value2: BigIntStr | number | bigint,
    ): boolean {
        return toBigInt(value1) >= toBigInt(value2);
    },

    lessThan(value1: BigIntStr | number | bigint, value2: BigIntStr | number | bigint): boolean {
        return toBigInt(value1) < toBigInt(value2);
    },

    toLocaleString(number: BigIntStr | number | bigint, locale?: string): string {
        const typeOfArg = typeof number;
        if (typeof number === "bigint") {
            return BigInt(number).toLocaleString(locale || getLocale());
        } else if (typeof number === "number") {
            return new Number(number || 0).toLocaleString(locale || getLocale());
        }

        return BigIntStr.toBigInt(number).toLocaleString(locale || getLocale());
    },
};

function toBigInt(BigIntStr: BigIntStr | number | bigint | string): bigint {
    return BigInt(BigIntStr as string);
}
