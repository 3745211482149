export function isSrcCached(src: string): boolean {
    if (typeof window === "undefined") {
        return false;
    }

    const image = new Image();
    image.src = src;

    return image.complete;
}
