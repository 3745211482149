import { DEFAULT_EMAIL_TEMPLATE_BACKGROUND } from "@novel/shared/utils/appConstants";
import { HSBColor, rgbToHsb } from "@shopify/polaris";
import parseColor from "parse-color";

const shorthandHexRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
const fullHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

// minimal recommended contrast ratio is 4.5, or 3 for larger font-sizes
export const COLOR_CONTRAST_THRESHOLD = 4.5;

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
type RGBColor = { red: number; green: number; blue: number };
function hexToRgb(hex: string): RGBColor | null {
    hex = hex.replace(shorthandHexRegex, replaceShorthand);

    const result = fullHexRegex.exec(hex);
    return result
        ? {
              red: parseInt(result[1], 16),
              green: parseInt(result[2], 16),
              blue: parseInt(result[3], 16),
          }
        : null;
}

export function formatHexToHsb(hexColorValue: string): HSBColor {
    const color = isHexColorInvalid(hexColorValue)
        ? DEFAULT_EMAIL_TEMPLATE_BACKGROUND
        : hexColorValue;
    return rgbToHsb(hexToRgb(color)!);
}

export const hexColorRegex = /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/;
export const isHexColorInvalid = (value: string): boolean => {
    if (typeof value === "string") return !RegExp(hexColorRegex).test(value);
    return true;
};

function replaceShorthand(_m: string, r: string, g: string, b: string): string {
    return r + r + g + g + b + b;
}

export function getBrightness(color?: string): number {
    if (!color) {
        // by default assume black background
        return 0;
    }
    const {
        rgb: [r, g, b],
    } = parseColor(color);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness;
}

export function brightnessAboveThreshold(color?: string, threshold = 125): boolean {
    return getBrightness(color) > threshold;
}

export function invertedBrightnessColor(color?: string): string {
    return brightnessAboveThreshold(color) ? "rgb(0,0,0)" : "rgb(255,255,255)";
}

export function luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map((v: number) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function contrast(rgb1: number[], rgb2: number[]): number {
    const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
    const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
}

export const isTooDarkCalculator = (colorHash: string) => {
    const c = colorHash.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    if (luma < 40) {
        return true;
    }

    return false;
};
