export function redirectOnFrontend(url: string): Window | null {
    if (typeof window === "undefined") {
        return null;
    }
    try {
        return (window.top || window).open(url, "_self");
    } catch (e) {
        return window.open(url, "_self");
    }
}

export function openInNewTab(url: string): Window | null {
    if (typeof window === "undefined") {
        return null;
    }

    try {
        return (window.top || window).open(url, "_blank");
    } catch (e) {
        return window.open(url, "_blank");
    }
}

const iOS = () => {
    return (
        ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
            navigator.platform,
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
};

export const checkDevice = (): "Android" | "iOS" | "unknown" => {
    if (/android/i.test(navigator.userAgent)) {
        return "Android";
    }
    if (iOS()) {
        return "iOS";
    }
    return "unknown";
};

export function deepLinkRedirect(url: string): void {
    if (typeof window === "undefined") {
        return;
    }

    window.location.href = url;
}
