import { MediaMinWidths } from "@novel/shared/utils/uiConstants";
import { useMediaQuery } from "react-responsive";

export function useIsMobile(): boolean {
    return !useMediaQuery({ minWidth: MediaMinWidths.Medium });
}

export function useIsTabletWidth(): boolean {
    return !useMediaQuery({ minWidth: MediaMinWidths.Large });
}
