import { DependencyList, useLayoutEffect } from "react";

export function useWindowEventEffect<K extends keyof WindowEventMap>(
    eventName: K,
    callback: (event: WindowEventMap[K]) => void,
    options: AddEventListenerOptions = {},
    dependencies: DependencyList = [],
): void {
    if (typeof window === "undefined") {
        return;
    }

    useLayoutEffect(() => {
        window.addEventListener(eventName, callback, options);
        return () => {
            window.removeEventListener(eventName, callback, options);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventName, callback, options, ...dependencies]);
}
