import { DependencyList, useCallback } from "react";
import { globalErrorBoundaryUtils } from "@novel/shared/components/GlobalErrorBoundary";

// concept taken from
// https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
export function useAsyncCallback<T extends any[], V>(
    callback: (...args: T) => Promise<V> | undefined,
    deps: DependencyList,
): (...args: T) => Promise<V | void> {
    return useCallback(
        async (...args: T) => {
            try {
                return await callback(...args);
            } catch (e: any) {
                globalErrorBoundaryUtils.throwPseudoError(e);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps,
    );
}
