import React, { useMemo } from "react";
import { Modal, ModalProps } from "rsuite";
import cx from "classnames";
import { useIsMobile } from "@novel/shared/hooks/useIsMobile";
import { findMatchingParent } from "@novel/shared/utils/findMatchingParent";
import { useWindowEventEffect } from "@novel/shared/hooks/useWindowEventEffect";
export interface NovelModalProps extends ModalProps {
  readonly blurBackground?: boolean;
  readonly blurBackgroundHeavy?: boolean;
  readonly unclosable?: boolean;
  readonly fullScreenOnMobile?: boolean;
  readonly rootElementId?: string;
}
export function NovelModal({
  children,
  unclosable,
  fullScreenOnMobile,
  blurBackground = false,
  blurBackgroundHeavy = false,
  rootElementId = "PolarisPortalsContainer",
  open = false,
  ...props
}: NovelModalProps): JSX.Element {
  const isMobile = useIsMobile();
  const showFullScreenMobile = fullScreenOnMobile && isMobile;

  // need to put the modal inside of polaris to get the correct styling on contained polaris elements
  const container = typeof window !== "undefined" ? document.getElementById(rootElementId) || undefined : undefined;
  const modalId = useMemo(() => `modalId_${rootElementId}_${props.className}`, [props.className, rootElementId]);

  // making modal block scrolling but keep scroll position
  // useLayoutEffect(() => {
  //     const modalWasOpen = open;
  //     const modalElement = document.getElementById(modalId);

  //     let reenableScroll: null | (() => void);
  //     if (modalWasOpen && modalElement) {
  //         reenableScroll = disableScroll(modalElement);
  //     }

  //     return () => {
  //         if (reenableScroll) {
  //             reenableScroll();
  //         }
  //     };
  // }, [open, modalId]);

  useWindowEventEffect("keydown", e => {
    if (open && !unclosable && e.key === "Escape" && props.onClose) {
      props.onClose(e as any);
    }
  }, {
    capture: true
  });
  useWindowEventEffect("mousedown", e => {
    // if modal closable and click is not on modal or intercom
    if (open && !unclosable && showFullScreenMobile && props.onClose && !findMatchingParent({
      startingElement: e.target,
      test: element => element.id === modalId || element.classList.contains("intercom-launcher-frame")
    })) {
      e.stopImmediatePropagation();
      props.onClose(e as any);
    }
  }, {
    capture: true
  });
  return <React.Fragment>
            {showFullScreenMobile ? <div id={modalId} className={cx("mobile-full-screen-modal", props.className)}>
                    {children}
                </div> : <Modal id={modalId} container={container} keyboard={!unclosable} backdrop={unclosable ? "static" : true} open={open} backdropClassName={cx(props.className ? `${props.className}-backdrop` : "", modalId)} {...props}>
                    {children}
                </Modal>}
            <style>{`
                ${!unclosable ? "" : `
                        #${modalId} .rs-modal-header-close {
                            display: none;
                        }
                        .rs-modal-dialog {
                            animation: none !important;
                        }
                    `}
                ${!blurBackground ? !blurBackgroundHeavy ? "" : `
                        .${modalId}.rs-modal-backdrop {
                            backdrop-filter: blur(10px);
                            -webkit-backdrop-filter: blur(10px);
                        }
                    ` : `
                    .${modalId}.rs-modal-backdrop {
                        backdrop-filter: blur(5px);
                        -webkit-backdrop-filter: blur(5px);
                    }
                `}
                ${
      // put all help chats in front of modal
      open ? `
                    #dummy-chat-button-iframe,
                    #front-chat-holder,
                    #gorgias-chat-container,
                    .intercom-launcher,
                    #dummy-chat-button-iframe,
                    #ShopifyChat {
                        z-index: 9999999 !important;
                    }
                ` : ""}
            `}</style>
        </React.Fragment>;
}
NovelModal.Title = Modal.Title;
NovelModal.Body = Modal.Body;
NovelModal.Footer = Modal.Footer;
NovelModal.Header = Modal.Header;