import { BigIntStr } from "@novel/shared/interfaces/shared/BigIntStr";
import { textToNumber } from "@novel/shared/utils/textToNumber";
import { CurrencyCode as FiatCurrencyCode } from "@novel/shared/__generated__/graphql";
import { getLocale } from "./getLocale";
import { SupportedBlockchain } from "@novel/shared/utils/cryptoLinkUtils";
import { numberWithCommas } from "@novel/shared/utils/numberWithCommas";

export enum CryptoCurrencyCode {
    Eth = "ETH",
    Matic = "MATIC",
}

export enum OtherStoreCreditCurrencyCode {
    Pts = "PTS",
}

export type StoreCreditCurrencyCode = OtherStoreCreditCurrencyCode | FiatCurrencyCode;
export type StoreCreditCurrencyMap = { [key in StoreCreditCurrencyCode]?: BigIntStr };

export { FiatCurrencyCode };
export type CryptoOrFiatCurrencyCode = CryptoCurrencyCode | FiatCurrencyCode;
type AllCurrencyCode = CryptoOrFiatCurrencyCode | StoreCreditCurrencyCode;

export function getCurrencyConfigObj(currencyCode: AllCurrencyCode): CurrencyPrefixAndSuffix {
    return currencyPrefixAndSuffixLookup[currencyCode];
}

export function getCurrencySymbol(currencyCode: AllCurrencyCode): string {
    return (
        currencyPrefixAndSuffixLookup[currencyCode].prefix?.trim() ||
        currencyPrefixAndSuffixLookup[currencyCode].suffix?.trim() ||
        ""
    );
}

// export for use in places where this conversion needs to be done (i.e., the division) but do not need the currency symbol
export function convertNumberForFormatMoney(
    value: number | string | bigint | BigIntStr,
    currencyCode: AllCurrencyCode,
): number {
    if (!currencyPrecisionLookup[currencyCode]) {
        console.warn(
            `no currency precision found for currencyCode: ${currencyCode}, falling back to 2`,
        );
    }

    const currencyPrecision = currencyPrecisionLookup[currencyCode]
        ? currencyPrecisionLookup[currencyCode].currencyPrecision
        : 2;

    if (typeof value === "string") {
        // in case BigInt is too big to fit in a number, doing this
        const decimalPosition = value.length - currencyPrecision;
        const beforeDecimal = value.slice(0, decimalPosition);
        const afterDecimal = value.slice(decimalPosition);
        return +`${beforeDecimal}.${afterDecimal}`;
    } else if (typeof value === "bigint") {
        const divisor = BigInt(Math.pow(10, currencyPrecision));
        const divisionResult = value / divisor;
        const moduloResult = value / divisor;
        return +`${divisionResult}.${moduloResult}`;
    } else {
        return +(value || 0) / Math.pow(10, currencyPrecision);
    }
}

export function formatMoney(
    value: number | string | bigint | BigIntStr,
    currencyCode: AllCurrencyCode,
    nothingAfterDecimal = false,
    removeNegative = false,
): string {
    // prices are stored in cents as we intend to support multi-currency at some point
    const resolvedCurrencyCode = currencyCode || FiatCurrencyCode.Usd;
    const convertedNumber = removeNegative
        ? convertNumberForFormatMoney(value, resolvedCurrencyCode) * -1
        : convertNumberForFormatMoney(value, resolvedCurrencyCode);

    if (Object.values(CryptoCurrencyCode).includes(resolvedCurrencyCode as CryptoCurrencyCode)) {
        return `${
            nothingAfterDecimal ? Math.round(convertedNumber) : convertedNumber
        } ${resolvedCurrencyCode}`;
    }

    if (resolvedCurrencyCode === OtherStoreCreditCurrencyCode.Pts) {
        // for points, not including "points" in string, but converting into comma-separated number
        return `${numberWithCommas(
            nothingAfterDecimal ? Math.round(convertedNumber) : convertedNumber,
        )}`;
    }

    return new Intl.NumberFormat(getLocale(), {
        style: "currency",
        currency: resolvedCurrencyCode as FiatCurrencyCode,
    }).format(nothingAfterDecimal ? Math.round(convertedNumber) : convertedNumber);
}

export function unformatMoney(
    value: number | string,
    currencyCode: AllCurrencyCode,
    roundToInteger = true,
): number {
    const { currencyPrecision } = currencyPrecisionLookup[currencyCode];
    const converted = textToNumber(value) * Math.pow(10, currencyPrecision);
    if (!roundToInteger) {
        return converted;
    }
    return Math.round(converted);
}

const currencyPrecisionLookup: {
    [key in AllCurrencyCode]: {
        currencyName: string;
        currencyPrecision: number;
    };
} = {
    PTS: { currencyName: "Points", currencyPrecision: 0 },
    ETH: { currencyName: "Ethereum", currencyPrecision: 18 },
    MATIC: { currencyName: "Matic", currencyPrecision: 18 },
    AED: { currencyName: "United Arab Emirates dirham", currencyPrecision: 2 },
    AFN: { currencyName: "Afghanistan afghani", currencyPrecision: 2 },
    AMD: { currencyName: "Armenian dram", currencyPrecision: 2 },
    ANG: {
        currencyName: "Netherlands Antillean guilder",
        currencyPrecision: 2,
    },
    AOA: { currencyName: "Angola kwanza", currencyPrecision: 2 },
    ARS: { currencyName: "Argentine peso", currencyPrecision: 2 },
    AUD: { currencyName: "Australian dollar", currencyPrecision: 2 },
    AWG: { currencyName: "Aruban florin", currencyPrecision: 2 },
    AZN: { currencyName: "Azerbaijani manat", currencyPrecision: 2 },
    BAM: {
        currencyName: "Bosnia-Herzegovina convertible mark",
        currencyPrecision: 2,
    },
    BBD: { currencyName: "Barbados dollar", currencyPrecision: 2 },
    BDT: { currencyName: "Bangladeshi taka", currencyPrecision: 2 },
    BGN: { currencyName: "Bulgarian lev", currencyPrecision: 2 },
    BHD: { currencyName: "Bahraini dinar", currencyPrecision: 3 },
    BIF: { currencyName: "Burundian franc", currencyPrecision: 0 },
    BMD: { currencyName: "Bermuda dollar", currencyPrecision: 2 },
    BND: { currencyName: "Brunei dollar", currencyPrecision: 2 },
    BOB: { currencyName: "Bolivian boliviano", currencyPrecision: 2 },
    BRL: { currencyName: "Brazilian real", currencyPrecision: 2 },
    BSD: { currencyName: "Bahamian dollar", currencyPrecision: 2 },
    BWP: { currencyName: "Botswana pula", currencyPrecision: 2 },
    BYR: { currencyName: "Belarussian ruble", currencyPrecision: 0 },
    BYN: { currencyName: "Belarussian ruble", currencyPrecision: 2 },
    BZD: { currencyName: "Belize dollar", currencyPrecision: 2 },
    CAD: { currencyName: "Canadian dollar", currencyPrecision: 2 },
    CDF: { currencyName: "Congolese franc", currencyPrecision: 2 },
    CHF: { currencyName: "Swiss franc", currencyPrecision: 2 },
    CLP: { currencyName: "Chilean peso", currencyPrecision: 0 },
    CNY: { currencyName: "Chinese yuan renminbi", currencyPrecision: 2 },
    COP: { currencyName: "Columbian peso", currencyPrecision: 2 },
    CRC: { currencyName: "Costa Rican colon", currencyPrecision: 2 },
    CVE: { currencyName: "Cape Verde escudo", currencyPrecision: 2 },
    CZK: { currencyName: "Czech koruna", currencyPrecision: 2 },
    DJF: { currencyName: "Djiboutian franc", currencyPrecision: 0 },
    DKK: { currencyName: "Danish krone", currencyPrecision: 2 },
    DOP: { currencyName: "Dominican peso", currencyPrecision: 2 },
    DZD: { currencyName: "Algerian dinar", currencyPrecision: 2 },
    EGP: { currencyName: "Egyptian pound", currencyPrecision: 2 },
    ERN: { currencyName: "Eritrean nakfa", currencyPrecision: 2 },
    ETB: { currencyName: "Ethiopian birr", currencyPrecision: 2 },
    EUR: { currencyName: "Euro", currencyPrecision: 2 },
    FJD: { currencyName: "Fiji dollar", currencyPrecision: 2 },
    FKP: { currencyName: "Falkland Islands pound", currencyPrecision: 2 },
    GBP: { currencyName: "British pound sterling", currencyPrecision: 2 },
    GEL: { currencyName: "Georgian lari", currencyPrecision: 2 },
    GHS: { currencyName: "Ghana cedi", currencyPrecision: 2 },
    GIP: { currencyName: "Gibraltar pound", currencyPrecision: 2 },
    GMD: { currencyName: "Gambian dalasi", currencyPrecision: 2 },
    GNF: { currencyName: "Guinean franc", currencyPrecision: 0 },
    GTQ: { currencyName: "Guatemalan quetzal", currencyPrecision: 2 },
    GYD: { currencyName: "Guyanese dollar", currencyPrecision: 2 },
    HKD: { currencyName: "Hong Kong dollar", currencyPrecision: 2 },
    HNL: { currencyName: "Hunduran Lempira", currencyPrecision: 2 },
    HRK: { currencyName: "Croatian kuna", currencyPrecision: 2 },
    HTG: { currencyName: "Haitian gourde", currencyPrecision: 2 },
    HUF: { currencyName: "Hungarian forint", currencyPrecision: 2 },
    IDR: { currencyName: "Indonesian rupiah", currencyPrecision: 2 },
    ILS: { currencyName: "Israeli sheqel", currencyPrecision: 2 },
    INR: { currencyName: "Indian rupee", currencyPrecision: 2 },
    IQD: { currencyName: "Iraqi dinar", currencyPrecision: 3 },
    ISK: { currencyName: "Icelandic krona", currencyPrecision: 2 },
    JMD: { currencyName: "Jamaican dollar", currencyPrecision: 2 },
    JOD: { currencyName: "Jordanian dinar", currencyPrecision: 3 },
    JPY: { currencyName: "Japanese yen", currencyPrecision: 0 },
    KES: { currencyName: "Kenyan shilling", currencyPrecision: 2 },
    KGS: { currencyName: "Kyrgyzstani som", currencyPrecision: 2 },
    KHR: { currencyName: "Cambodian riel", currencyPrecision: 2 },
    KMF: { currencyName: "Comoro franc", currencyPrecision: 0 },
    KRW: { currencyName: "South Korean won", currencyPrecision: 0 },
    KWD: { currencyName: "Kuwaiti dinar", currencyPrecision: 3 },
    KYD: { currencyName: "Cayman Islands dollar", currencyPrecision: 2 },
    KZT: { currencyName: "Kazakhstani tenge", currencyPrecision: 2 },
    LAK: { currencyName: "Lao kip", currencyPrecision: 2 },
    LBP: { currencyName: "Lebanese pound", currencyPrecision: 2 },
    LKR: { currencyName: "Sri Lanka rupee", currencyPrecision: 2 },
    LRD: { currencyName: "Liberian dollar", currencyPrecision: 2 },
    LSL: { currencyName: "Lesotho loti", currencyPrecision: 2 },
    LTL: { currencyName: "Lithuanian litas", currencyPrecision: 2 },
    LVL: { currencyName: "Latvian lats", currencyPrecision: 2 },
    MAD: { currencyName: "Moroccan dirham", currencyPrecision: 2 },
    MDL: { currencyName: "Moldovan leu", currencyPrecision: 2 },
    MGA: { currencyName: "Malagasy ariary", currencyPrecision: 0 },
    MKD: { currencyName: "Macedonian denar", currencyPrecision: 2 },
    MMK: { currencyName: "Myanmar kyat", currencyPrecision: 2 },
    MNT: { currencyName: "Mongolian tugrik", currencyPrecision: 2 },
    MOP: { currencyName: "Macanese pataca", currencyPrecision: 2 },
    MUR: { currencyName: "Mauritius rupee", currencyPrecision: 2 },
    MVR: { currencyName: "Maldivian rufiyaa", currencyPrecision: 2 },
    MWK: { currencyName: "Malawian kwacha", currencyPrecision: 2 },
    MXN: { currencyName: "Mexican peso", currencyPrecision: 2 },
    MYR: { currencyName: "Malaysian ringgit", currencyPrecision: 2 },
    MZN: { currencyName: "Mozambican metical", currencyPrecision: 2 },
    NAD: { currencyName: "Namibian dollar", currencyPrecision: 2 },
    NGN: { currencyName: "Nigerian naira", currencyPrecision: 2 },
    NIO: { currencyName: "Cordoba oro", currencyPrecision: 2 },
    NOK: { currencyName: "Norwegian krone", currencyPrecision: 2 },
    NPR: { currencyName: "Nepalese rupee", currencyPrecision: 2 },
    NZD: { currencyName: "New Zealand dollar", currencyPrecision: 2 },
    OMR: { currencyName: "Omani rial", currencyPrecision: 3 },
    PAB: { currencyName: "Panamanian balboa", currencyPrecision: 2 },
    PEN: { currencyName: "Peruvian nuevo sol", currencyPrecision: 2 },
    PGK: { currencyName: "Papua New Guinean kina", currencyPrecision: 2 },
    PHP: { currencyName: "Philippine peso", currencyPrecision: 2 },
    PKR: { currencyName: "Pakistan rupee", currencyPrecision: 2 },
    PLN: { currencyName: "Polish zloty", currencyPrecision: 2 },
    PYG: { currencyName: "Paraguayan guarani", currencyPrecision: 0 },
    QAR: { currencyName: "Qatari rial", currencyPrecision: 2 },
    RON: { currencyName: "Romanian leu", currencyPrecision: 2 },
    RSD: { currencyName: "Serbian dinar", currencyPrecision: 2 },
    RUB: { currencyName: "Russian ruble", currencyPrecision: 2 },
    RWF: { currencyName: "Rwanda franc", currencyPrecision: 0 },
    SAR: { currencyName: "Saudi Arabian riyal", currencyPrecision: 2 },
    SBD: { currencyName: "Solomon Islands dollar", currencyPrecision: 2 },
    SCR: { currencyName: "Seychelles rupee", currencyPrecision: 2 },
    SEK: { currencyName: "Swedish krona", currencyPrecision: 2 },
    SGD: { currencyName: "Singapore dollar", currencyPrecision: 2 },
    SHP: { currencyName: "Saint Helena pound", currencyPrecision: 2 },
    SLL: { currencyName: "Sierra Leonean leone", currencyPrecision: 2 },
    SOS: { currencyName: "Somali shilling", currencyPrecision: 2 },
    SRD: { currencyName: "Surinamese dollar", currencyPrecision: 2 },
    SSP: { currencyName: "South Sudanese pound", currencyPrecision: 2 },
    STD: { currencyName: "Sao Tome and Principe dobra", currencyPrecision: 2 },
    SYP: { currencyName: "Syrian pound", currencyPrecision: 2 },
    SZL: { currencyName: "Swaziland lilangeni", currencyPrecision: 2 },
    THB: { currencyName: "Thai baht", currencyPrecision: 2 },
    TJS: { currencyName: "Tajikistani somoni", currencyPrecision: 2 },
    TND: { currencyName: "Tunisian dinar", currencyPrecision: 3 },
    TOP: { currencyName: "Tongan pa’anga", currencyPrecision: 2 },
    TRY: { currencyName: "Turkish lira", currencyPrecision: 2 },
    TTD: { currencyName: "Trinidad and Tobago dollar", currencyPrecision: 2 },
    TWD: { currencyName: "Taiwan dollar", currencyPrecision: 2 },
    TZS: { currencyName: "Tanzanian shilling", currencyPrecision: 2 },
    UAH: { currencyName: "Ukrainian hryvnia", currencyPrecision: 2 },
    UGX: { currencyName: "Ugandan shilling", currencyPrecision: 2 },
    USD: { currencyName: "United States dollar", currencyPrecision: 2 },
    UYU: { currencyName: "Uruguayan peso", currencyPrecision: 2 },
    UZS: { currencyName: "Uzbekistan som", currencyPrecision: 2 },
    VEF: { currencyName: "Venezuelan bolivar fuerte", currencyPrecision: 2 },
    VND: { currencyName: "Vietnamese dong", currencyPrecision: 0 },
    VUV: { currencyName: "Vanuatu vatu", currencyPrecision: 0 },
    WST: { currencyName: "Samoan tala", currencyPrecision: 2 },
    XAF: { currencyName: "CFA franc BEAC", currencyPrecision: 0 },
    XCD: { currencyName: "East Caribbean dollar", currencyPrecision: 2 },
    XOF: { currencyName: "CFA Franc BCEAO", currencyPrecision: 0 },
    XPF: { currencyName: "CFP franc", currencyPrecision: 0 },
    YER: { currencyName: "Yemeni rial", currencyPrecision: 2 },
    ZAR: { currencyName: "South African rand", currencyPrecision: 2 },
    ZMW: { currencyName: "Zambian kwacha", currencyPrecision: 2 },

    ALL: { currencyName: "Albanian Lek", currencyPrecision: 2 },
    BTN: { currencyName: "Bhutan Ngultrum", currencyPrecision: 2 },
    IRR: { currencyName: "Iranian Rial", currencyPrecision: 2 },
    JEP: { currencyName: "Jersey Pounds", currencyPrecision: 2 },
    KID: { currencyName: "Kiribati dollar", currencyPrecision: 2 },
    LYD: { currencyName: "Libyan Dinar", currencyPrecision: 3 },
    MRU: { currencyName: "Mauritanian ouguiya", currencyPrecision: 2 },
    SDG: { currencyName: "Sudanese Pound", currencyPrecision: 2 },
    STN: { currencyName: "Sao Tome and Principe Dobra", currencyPrecision: 2 },
    VED: { currencyName: "Venezuelan Bolivar", currencyPrecision: 2 },
    TMT: { currencyName: "Turkmenistan New Manat", currencyPrecision: 2 },
    VES: { currencyName: "Venezuela Sovereign Bolivar", currencyPrecision: 2 },
    XXX: { currencyName: "Unrecognized currency", currencyPrecision: 2 },
};

type CurrencyPrefixAndSuffix = {
    prefix: string;
    suffix: string;
    currencyName: string;
    currencyPrecision: number;
};

const currencyPrefixAndSuffixLookup: {
    [key in AllCurrencyCode]: CurrencyPrefixAndSuffix;
} = (() => {
    const currencyStringParsingRegex = /([^0-9.]*)[0-9-().]+([^0-9.]*)/;

    return Object.keys(currencyPrecisionLookup).reduce(
        (accum, curr) => {
            const [, prefix, suffix] =
                currencyStringParsingRegex.exec(formatMoney(0, curr as CryptoOrFiatCurrencyCode)) ||
                [];

            accum[curr as AllCurrencyCode] = {
                ...currencyPrecisionLookup[curr as AllCurrencyCode],
                currencyCode: curr as AllCurrencyCode,
                prefix: prefix || "",
                suffix: suffix || "",
            };
            return accum;
        },
        {} as {
            [key in AllCurrencyCode]: {
                currencyCode: AllCurrencyCode;
                prefix: string;
                suffix: string;
                currencyName: string;
                currencyPrecision: number;
            };
        },
    );
})();

export const getCryptoCurrencyCodeByBlockchain = (
    blockchain: SupportedBlockchain,
): CryptoCurrencyCode => {
    switch (blockchain) {
        case "ethereum":
        case "rinkeby":
        case "ropsten":
        case "goerli":
            return CryptoCurrencyCode.Eth;
        case "polygon":
        case "mumbai":
            return CryptoCurrencyCode.Matic;
        default:
            throw new Error(`CryptoCurrencyCode for blockchain ${blockchain} not found!`);
    }
};
