import { FixedPxBox } from "@novel/shared/components/Box";
import { NovelModal } from "@novel/shared/components/NovelModal";
import cx from "classnames";
import Image from "next/legacy/image";
import React, { useCallback, useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import AppleWalletIcon from "@novel/shared/icons/AppleWalletIcon.png";
import GoogleWalletIcon from "@novel/shared/icons/GoogleWalletIcon.png";
import { DynamicSharedPassPreviewStyle } from "@novel/shared/components/PassPreview/sharedStyle";
import { IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { StoreCreditCurrencyCode } from "@novel/shared/utils/formatMoney";
import { CurrencyCode } from "@novel/shared/__generated__/graphql";
import { AndroidFrontPass } from "@novel/shared/components/PassPreview/shared/AndroidFrontPass";
import { AppleFrontPass } from "@novel/shared/components/PassPreview/shared/AppleFrontPass";
import { FixedDimensionMedia } from "@novel/shared/components/FixedDimensionMedia";
import { DEFAULT_PASS_GOOGLE_LOGO_IMAGE, DEFAULT_PASS_APPLE_LOGO_IMAGE } from "@novel/shared/utils/appConstants";

// styling with this is necessary for SSR to work
const LayoutDesktopCmpStyle = createGlobalStyle`
    .rs-modal-body {
        overflow: hidden !important;
        max-height: initial !important;
    }

    .rs-modal-content {
        margin: auto;
        background: #fff;
        min-height: min(556px, 100vh);
        max-width: 587px;
        border-radius: 10px 12px 12px 10px !important;
        padding-left: 20px;
        padding-top: 20px;
        overflow: auto;
        position: relative;
    }

    .black-area {
        display: none;
    }
    .main-box-form-area {
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
        overflow: hidden !important;
    }

    .rs-modal-body {
        height: 100%;
    }
    .white-area {
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        overflow: hidden !important;
        overflow-y: auto !important;
    }

    .small-logo-container {
        margin-left: auto;
        margin-right: auto;
    }

    .pass-modal-image-wrapper {
        display: none !important;
        left: calc(50% - 225px) !important;
    }

    @media (min-width: 970px) {
        .small-logo-container {
            display: none !important;
        }
        .pass-modal-image-wrapper {
            display: inline-flex !important;
        }

        .rs-modal-content {
            border-radius: 20px;
            color: #000;
            font-family: Poppins,sans-serif;
            overflow: initial;
            align-items: stretch;
            max-height: calc(100vh - 138px);
            width: 997px;
            background: #fff !important;
            box-shadow: none !important;
            display: flex !important;
            flex-direction: column;
            padding: 0;
            max-width: 997px;
        }
        .rs-modal {
            width: calc(100% - 80px);
        }
        .rs-modal-dialog {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .rs-modal-body {
            overflow: hidden !important;
            max-height: 100% !important;
            padding: 0;
            margin-top: 0;
            min-width: 295px;
            height: 558px;
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
        .white-area {
            display: flex;
            position: relative;
            flex-direction: row;
            -webkit-box-flex: 1;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
        }
        .black-area {
            background: #2A2A2A;
            border-radius: 0 10px 10px 0;
            overflow-y: auto;
            max-width: 50%;
            display: flex;
            position: relative;
            flex-direction: row;
            min-width: 450px;
            min-height: 100%;
            align-items: center;
            justify-content: center;
        }
        .main-box-form-area {
            position: relative;
            height: 100%;
            justify-content: inherit;
            align-items: inherit;
        }
        .balance-text {
            font-size: 9px;
            letting-spacing: 1px;
            font-weight: 600;
        }
        .balance-text-ios {
            text-transform: uppercase;
        }
        .mobile-font-size-xs {
            font-weight: 300;
            font-size: 10px;
            line-height: 20px;
        }
        .mobile-font-size-sm {
            font-size: 12px;
        }
        .mobile-font-size-lg {
            font-size: 13px;
        }
        .size-13 {
            font-size: 13px;
        }
        .size-15 {
            font-size: 15px;
        }
        .text-align-center {
            text-align: center;
        }
        .mobile-size-logo-text {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
        }  
        .referral-qr-code-container {
            padding: 8px;
            background: white;
            border-radius: 4px;
            align-items: center;
        }
        .box-content-login-prompt {
            align-items: flex-start;
        }
    }
`;
export const LayoutDesktopCmp = (props: Omit<IRewardsTierCreate, "tierIndex"> & {
  readonly userDisplayName?: string;
  readonly showPseudoQr?: boolean;
  readonly children: React.ReactNode;
  readonly orgName?: string;
  readonly userId?: string;
  readonly isLogin?: boolean;
  readonly currencyCode?: StoreCreditCurrencyCode;
  readonly authLink?: string;
  readonly guessedPhoneOperatingSystem?: "ios" | "android";
  readonly passOrgNameOverride?: string;
}): JSX.Element => {
  const {
    children,
    passOrgNameOverride,
    ...nonChildrenProps
  } = props;
  const {
    googleLogoImageUrl,
    backgroundColor,
    textColor,
    currencyCode = CurrencyCode.Usd,
    guessedPhoneOperatingSystem = "ios"
  } = nonChildrenProps;
  const [initialized, setInitialized] = useState(false);
  const [previewType, setPreviewType] = useState(guessedPhoneOperatingSystem);
  const setPreviewToIos = useCallback(() => setPreviewType("ios"), []);
  const setPreviewToAndroid = useCallback(() => setPreviewType("android"), []);

  // to make the modal pop-up animation happen
  useEffect(() => {
    setInitialized(true);
  }, []);
  if (!initialized) {
    return <DynamicSharedPassPreviewStyle backgroundColor={backgroundColor ? backgroundColor : "#000000"} textColor={textColor ? textColor : "#ffffff"} />;
  }
  const isDefaultOverheadImage = googleLogoImageUrl === DEFAULT_PASS_GOOGLE_LOGO_IMAGE;
  return <React.Fragment>
            <NovelModal className="pass-login-modal" unclosable open fullScreenOnMobile>
                <div className="pass-modal-image-wrapper">
                    <FixedDimensionMedia className={cx("pass-modal-image", {
          "default-image": isDefaultOverheadImage
        })} calculation="contain" size={isDefaultOverheadImage ? "mediumSmaller" : "mediumSmall"} alt="pass-modal-image" containerBackgroundColor="transparent" hasBoxShadow={false}
        // cheating for the default state
        src={isDefaultOverheadImage ? DEFAULT_PASS_APPLE_LOGO_IMAGE : googleLogoImageUrl} />
                </div>
                <NovelModal.Body>
                    <FixedPxBox className="main-box-form-area">
                        <FixedPxBox className="white-area">{children}</FixedPxBox>
                        <div className="black-area">
                            <FixedPxBox column rowGap={1} className="black-box-content" justifyContent="center" alignItems={"center"}>
                                <FixedPxBox className="mobile-border" column>
                                    <FixedPxBox className={cx({
                  "mobile-case-upper-apple": previewType === "ios",
                  "mobile-case-upper-android": previewType === "android"
                })}>
                                        {previewType === "ios" ? <AppleFrontPass {...nonChildrenProps} currencyCode={currencyCode} modalPreview /> : <AndroidFrontPass {...nonChildrenProps} passOrgNameOverride={passOrgNameOverride} currencyCode={currencyCode} modalPreview />}
                                    </FixedPxBox>
                                    <div style={{
                  borderTop: "1px solid black",
                  zIndex: "999",
                  width: "350px"
                }} />
                                </FixedPxBox>
                                <div className="box-footer-mobile">
                                    <FixedPxBox columnGap={1} justifyContent="center" alignItems="center" onClick={setPreviewToIos} className={cx("preview-device-button", {
                  "preview-device-button-selected": previewType === "ios"
                })}>
                                        <Image src={AppleWalletIcon} alt="Logo" height={12} width={15} />{" "}
                                        Apple
                                    </FixedPxBox>
                                    <FixedPxBox columnGap={1} justifyContent="center" alignItems="center" onClick={setPreviewToAndroid} className={cx("preview-device-button", {
                  "preview-device-button-selected": previewType === "android"
                })}>
                                        <Image src={GoogleWalletIcon} alt="Logo" height={12} width={15} />
                                        Google
                                    </FixedPxBox>
                                </div>
                            </FixedPxBox>
                        </div>
                    </FixedPxBox>
                </NovelModal.Body>
            </NovelModal>
            <LayoutDesktopCmpStyle />
            <DynamicSharedPassPreviewStyle backgroundColor={backgroundColor ? backgroundColor : "#000000"} textColor={textColor ? textColor : "#ffffff"} />
        </React.Fragment>;
};