import React from "react";
import styled from "styled-components";
import { MediaMinWidths } from "@novel/shared/utils/uiConstants";
interface FlexDivProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly inlineFlex?: boolean;
  readonly usePx?: boolean;
  readonly position?: React.CSSProperties["position"];
  readonly column?: boolean;
  readonly mobileDirection?: React.CSSProperties["flexDirection"];
  readonly rowGap?: number;
  readonly mobileRowGap?: number;
  readonly columnGap?: number;
  readonly alignItems?: React.CSSProperties["alignItems"];
  readonly mobileAlignItems?: React.CSSProperties["alignItems"];
  readonly justifyContent?: React.CSSProperties["justifyContent"];
  readonly mobileJustifyContent?: React.CSSProperties["justifyContent"];
  readonly textAlign?: React.CSSProperties["textAlign"];
  readonly left?: string | number;
  readonly bottom?: string | number;
  readonly flexGrow?: number;
  readonly width?: string | number;
  readonly mobileWidth?: string | number;
  readonly minWidth?: string | number;
  readonly maxWidth?: string | number;
  readonly mobileMinWidth?: string | number;
  readonly mobileMaxWidth?: string | number;
  readonly height?: string | number;
  readonly mobileHeight?: string | number;
  readonly minHeight?: string | number;
  readonly maxHeight?: string | number;
  readonly mobileMinHeight?: string | number;
  readonly mobileMaxHeight?: string | number;
  readonly flexWrap?: React.CSSProperties["flexWrap"];
  readonly mobileFlexWrap?: React.CSSProperties["flexWrap"];
  readonly overflow?: React.CSSProperties["overflow"];
  readonly overflowY?: React.CSSProperties["overflowY"];
  readonly overflowX?: React.CSSProperties["overflowX"];
  readonly m?: number;
  readonly mx?: number;
  readonly my?: number;
  readonly mb?: number;
  readonly mt?: number;
  readonly ml?: number;
  readonly mr?: number;
  readonly mobileMx?: number;
  readonly mobileMy?: number;
  readonly mobileMb?: number;
  readonly mobileMt?: number;
  readonly mobileMl?: number;
  readonly mobileMr?: number;
  readonly p?: number;
  readonly px?: number;
  readonly py?: number;
  readonly pb?: number;
  readonly pt?: number;
  readonly pl?: number;
  readonly pr?: number;
  readonly mobilePx?: number;
  readonly mobilePy?: number;
  readonly children: React.ReactNode;
}
export const FixedPxBox = ({
  children,
  ...props
}: FlexDivProps): JSX.Element => <Box usePx {...props}>
        {children}
    </Box>;
export const Box = styled.div<FlexDivProps>`
    display: ${props => props.inlineFlex ? "inline-flex" : "flex"};

    ${props => `position: ${props.position || "relative"};`}
    ${props => `flex-direction: ${props.column ? "column" : "row"};`}
    ${props => props.flexWrap && `flex-wrap: ${props.flexWrap};`}

    ${props => props.overflow && `overflow: ${props.overflow};`}
    ${props => props.overflowY && `overflow-y: ${props.overflowY};`}
    ${props => props.overflowX && `overflow-x: ${props.overflowX};`}

	${props => props.flexGrow && `flex-grow: ${props.flexGrow};`}
	${props => props.minWidth && `min-width: ${typeof props.minWidth === "number" ? `${props.minWidth}px` : props.minWidth};`}
  ${props => props.maxWidth && `max-width: ${typeof props.maxWidth === "number" ? `${props.maxWidth}px` : props.maxWidth};`}
        ${props => props.minHeight && `min-height: ${typeof props.minHeight === "number" ? `${props.minHeight}px` : props.minHeight};`}
    ${props => props.maxHeight && `max-height: ${typeof props.maxHeight === "number" ? `${props.maxHeight}px` : props.maxHeight};`}
  ${props => props.width && `width: ${typeof props.width === "number" ? `${props.width}px` : props.width};};`}
  ${props => props.height && `height: ${typeof props.height === "number" ? `${props.height}px` : props.height};};`}
  ${props => typeof props.left !== "undefined" ? `left: ${typeof props.left === "string" ? props.left : renderValue(props.left, props.usePx)};` : ""}
  ${props => typeof props.bottom !== "undefined" ? `bottom: ${typeof props.bottom === "string" ? props.bottom : renderValue(props.bottom, props.usePx)};` : ""}

  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => !!props.rowGap && `row-gap: ${renderValue(props.rowGap, props.usePx)};`}
  ${props => !!props.columnGap && `column-gap: ${renderValue(props.columnGap, props.usePx)};`}

  ${props => !!props.m && `margin: ${renderValue(props.m, props.usePx)};`}
  ${props => !!props.my && `margin-top: ${renderValue(props.my, props.usePx)}; margin-bottom: ${renderValue(props.my, props.usePx)};`}
  ${props => !!props.mx && `margin-left: ${renderValue(props.mx, props.usePx)}; margin-right: ${renderValue(props.mx, props.usePx)};`}
  ${props => !!props.mt && `margin-top: ${renderValue(props.mt, props.usePx)};`}
  ${props => !!props.mb && `margin-bottom: ${renderValue(props.mb, props.usePx)};`}
  ${props => !!props.ml && `margin-left: ${renderValue(props.ml, props.usePx)};`}
  ${props => !!props.mr && `margin-right: ${renderValue(props.mr, props.usePx)};`}

  ${props => !!props.p && `padding: ${renderValue(props.p, props.usePx)};`}
  ${props => !!props.py && `padding-top: ${renderValue(props.py, props.usePx)}; padding-bottom: ${renderValue(props.py, props.usePx)};`}
  ${props => !!props.px && `padding-left: ${renderValue(props.px, props.usePx)}; padding-right: ${renderValue(props.px, props.usePx)};`}
  ${props => !!props.pt && `padding-top: ${renderValue(props.pt, props.usePx)};`}
  ${props => !!props.pb && `padding-bottom: ${renderValue(props.pb, props.usePx)};`}
  ${props => !!props.pl && `padding-left: ${renderValue(props.pl, props.usePx)};`}
  ${props => !!props.pr && `padding-right: ${renderValue(props.pr, props.usePx)};`}

  @media screen and (max-width: ${MediaMinWidths.Large}px) {
        ${props => props.mobileDirection ? `
        flex-direction: ${props.mobileDirection};
    ` : ""}
        ${props => props.mobileFlexWrap ? `
        flex-wrap: ${props.mobileFlexWrap};
    ` : ""}
    ${props => props.mobileAlignItems ? `
        align-items: ${props.mobileAlignItems};
    ` : ""}
    ${props => props.mobileJustifyContent ? `
        justify-content: ${props.mobileJustifyContent};
    ` : ""}
                ${props => props.mobileMinHeight ? `
              min-height: ${props.mobileMinHeight};
          ` : ""}
          ${props => props.mobileMaxHeight ? `
              max-height: ${props.mobileMaxHeight};
          ` : ""}
                ${props => props.mobileMinWidth ? `
        min-width: ${props.mobileMinWidth};
    ` : ""}
    ${props => props.mobileMaxWidth ? `
        max-width: ${props.mobileMaxWidth};
    ` : ""}

    ${props => props.mobileWidth ? `
        width: ${props.mobileWidth};
    ` : ""}

    ${props => props.mobileHeight ? `
        height: ${props.mobileHeight};
    ` : ""}

      ${props => !!props.mobileMy && `margin-top: ${renderValue(props.mobileMy, props.usePx)}; margin-bottom: ${renderValue(props.mobileMy, props.usePx)};`}
      ${props => !!props.mobileMx && `margin-left: ${renderValue(props.mobileMx, props.usePx)}; margin-right: ${renderValue(props.mobileMx, props.usePx)};`}
      ${props => typeof props.mobileMt === "number" && `margin-top: ${renderValue(props.mobileMt, props.usePx)};`}
      ${props => typeof props.mobileMb === "number" && `margin-bottom: ${renderValue(props.mobileMb, props.usePx)};`}
      ${props => typeof props.mobileMl === "number" && `margin-left: ${renderValue(props.mobileMl, props.usePx)};`}
      ${props => typeof props.mobileMr === "number" && `margin-right: ${renderValue(props.mobileMr, props.usePx)};`}

      ${props => typeof props.mobilePy === "number" && `padding-top: ${renderValue(props.mobilePy, props.usePx)}; padding-bottom: ${renderValue(props.mobilePy, props.usePx)};`}
      ${props => typeof props.mobilePx === "number" && `padding-left: ${renderValue(props.mobilePx, props.usePx)}; padding-right: ${renderValue(props.mobilePx, props.usePx)};`}
      ${props => typeof props.mobileRowGap === "number" && `row-gap: ${renderValue(props.mobileRowGap, props.usePx)};`}
    }
`;
function renderValue(value: number, usePx?: boolean) {
  return usePx ? `${value * 10}px` : `${value}rem`;
}