import { IRewardsTierCreate } from "@novel/shared/interfaces/RewardsTier";
import { BigIntStr } from "@novel/shared/interfaces/shared/BigIntStr";
import {
    OtherStoreCreditCurrencyCode,
    StoreCreditCurrencyCode,
    formatMoney,
} from "@novel/shared/utils/formatMoney";

export function computeReferralString(
    rewardsTier: Pick<
        IRewardsTierCreate,
        "hasReferralReward" | "refereeRewardFlatAmount" | "perReferralRewardFlatAmount"
    >,
    currencyCode: StoreCreditCurrencyCode,
): string {
    const { hasReferralReward, refereeRewardFlatAmount, perReferralRewardFlatAmount } = rewardsTier;

    const refereeRewardsAmount = refereeRewardFlatAmount?.[currencyCode];
    const referralRewardsAmount = perReferralRewardFlatAmount?.[currencyCode];
    const hasRefereeRewards =
        typeof refereeRewardsAmount !== "undefined" && !BigIntStr.equals(refereeRewardsAmount, 0);
    const hasReferralRewards =
        typeof referralRewardsAmount !== "undefined" && !BigIntStr.equals(referralRewardsAmount, 0);

    if (!hasReferralReward) {
        return "💌 Refer a friend 💌";
    }

    if (hasRefereeRewards && hasReferralRewards) {
        return `Give ${formatMoney(refereeRewardsAmount, currencyCode)} ${
            currencyCode === OtherStoreCreditCurrencyCode.Pts ? "pts" : ""
        } 🤝 Get ${formatMoney(referralRewardsAmount, currencyCode)} ${
            currencyCode === OtherStoreCreditCurrencyCode.Pts ? "pts" : ""
        }`;
    } else if (!hasRefereeRewards && hasReferralRewards) {
        return `💰 Refer and earn ${formatMoney(referralRewardsAmount, currencyCode)} ${
            currencyCode === OtherStoreCreditCurrencyCode.Pts ? "pts" : ""
        } 💰`;
    } else if (hasRefereeRewards && !hasReferralRewards) {
        return `💌 Give a friend ${formatMoney(refereeRewardsAmount, currencyCode)} ${
            currencyCode === OtherStoreCreditCurrencyCode.Pts ? "pts" : ""
        } 💌`;
    } else {
        return "💌 Refer a friend 💌";
    }
}
