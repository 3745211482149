export function findMatchingParent({
    startingElement,
    test,
    includeStartingElement,
}: {
    startingElement: HTMLElement | EventTarget | null;
    test: (parentElem: HTMLElement) => boolean;
    includeStartingElement?: boolean;
}): HTMLElement | null {
    if (typeof document === "undefined" || !startingElement) {
        return null;
    }

    let currentElement: HTMLElement | null = startingElement as HTMLElement | null;
    do {
        if (
            currentElement &&
            (startingElement !== currentElement || includeStartingElement) &&
            test(currentElement)
        ) {
            return currentElement;
        }
    } while (
        currentElement !== (document as any) &&
        (currentElement = currentElement?.parentElement || null)
    );

    return null;
}
